<template>
  <transition name="fade">
    <div class="login_box">
      <!-- 左侧图片 -->
      <div class="picture">
        <img src="../assets/calendar.png" alt />
      </div>
      <!-- 右侧登录表单 -->
      <div class="header_box">
        <div class="header">
          <span>后台管理系统</span>
        </div>
        <!-- 登录表单 -->
        <div class="login-form">
          <div class="account_login">
            <span>账户登录</span>
          </div>
          <el-form :model="form">
            <el-form-item>
              <el-input
                v-model="form.userCode"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.password"
                type="password"
                placeholder="请输入密码"
                show-password
                @keydown.enter.native="submit"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="login-button" @click="submit">
            <el-button type="primary">登录</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { postRequest } from "@/api/api.js";
import global from "./../Global.vue";
import axios from "axios";
export default {
  data() {
    return {
      login: false,
      form: {
        userCode: "",
        password: "",
      },
    };
  },
  created() {
    var storage = window.localStorage;
    storage.clear();
    window.sessionStorage.removeItem("token");
  },
  mounted() {},

  methods: {
    submit() {
      var storage = window.localStorage;
      storage.clear();
      if (this.form.userCode && this.form.password) {
        var data = {
          userCode: this.form.userCode,
          password: this.form.password,
        };
        let loadingInstance = this.$loading({ text: "登录中" });
        postRequest("/login", data).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          }); //不回调
          if (res.status == 200) {
            window.sessionStorage.setItem("token", res.serviceId);
            this.$store.state.roomList = [];
            let userInfor = {
              userCode: res.data.userCode,
              userName: res.data.userName,
              phone: res.data.phone,
              deptCode: res.data.deptCode,
              userStatus: res.data.userStatus,
              remark: res.data.remark,
              superAdmin: res.data.superAdmin,
            };
            // this.selectHotel.userCode = res.data.userCode;
            // this.selectHotel.userName = res.data.userName;
            localStorage.setItem("user", JSON.stringify(userInfor));
            localStorage.setItem("power", JSON.stringify(res.data.rightList));
            localStorage.setItem(
              "hotelList",
              JSON.stringify(res.data.hotelList)
            );
            localStorage.setItem(
              "moduleList",
              JSON.stringify(res.data.moduleList)
            );
            console.log(res.data.superAdmin);
            if (res.data.superAdmin == 1) {
              this.$router.push("/superAdmin");
            } else {
              if (res.data.hotelList.length > 0) {
                this.$router.push("/choseHotel");
              } else {
                localStorage.setItem("findPage", 0);
                this.$router.push("/home");
              }
            }

            // this.dialogVisible = true;
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              type: "error",
              hasClose: true,
            }); //如果无法获取这一条数据，就报错
          }
        });
      } else {
        this.$message({
          duration: 5000,
          message: "请输入完整信息",
          center: true,
        });
      }
    },

    // 选择酒店
    choseHotel() {
      if (this.hotel == null) {
        this.$message({
          duration: 5000,
          message: "请选择酒店！",
          center: true,
        });
        return;
      }
      this.hotelList.forEach((item) => {
        if (item.id == this.hotel) {
          this.selectHotel.hotelId = item.id;
          this.selectHotel.hotelName = item.hotelName;
        }
      });
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
}

.login_box {
  display: flex;
  justify-content: space-evenly;
  margin-top: 174px;
  min-width: 1100px;
}
.header {
  text-align: center;
}
.header span {
  width: 100%;
  height: 44px;
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.85);
}
.header_box {
  width: 370px;
  height: 438px;
}

.picture img {
  width: 674px;
  height: 556px;
}
.el-form {
  width: 80%;
  margin: 0 auto;
}

.login-form {
  width: 100%;
  height: 340px;
  margin-top: 60px;
  background: #ffffff;
  box-shadow: 0px 9px 29px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}
.account_login {
  padding-top: 36px;
  margin-bottom: 30px;
  margin-left: 10%;
}
.account_login span {
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
}
.login-button {
  width: 80%;
  height: 46px;
  background: #1890ff;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}
.el-button {
  margin-top: 15px;
}
.el-form-item {
  margin-bottom: 20px;
}
.anniu {
  height: 30px;
  width: 80px;
}
</style>